
import { defineComponent } from 'vue';
import { entes } from '@/fuentes'
import { estadoGrupoDeEntes, listarGruposDeEntesUnaVez } from '@/gruposDeEntes'

export default defineComponent({
  name: 'Entes',
  data: () => ({
		entes: entes,
		estadoGrupoDeEntes,
		grupoDeEntes: ''
	}),
	mounted() {
		listarGruposDeEntesUnaVez().then(() => {
			if (this.estadoGrupoDeEntes.listado.length === 1) {
				this.grupoDeEntes = this.estadoGrupoDeEntes.listado[0].identificador
			}
		})
	},
	watch: {
		'grupoDeEntes': function() {
			if (!this.grupoDeEntes) {
				this.entes.estado.listado = []
				return
			}

			this.entes.listar(this.grupoDeEntes, 0)
		}
	},
});
