
<template>

	<div class="uk-overflow-auto contenedor">
		<table class="uk-table uk-table-small uk-table-divider registros">
			<caption>
				<h1>Entes <div uk-spinner v-if="entes.estado.listando"></div></h1>
				<hr>
				<label class="uk-form-label" for="grupo-entes-entes">Grupo de entes:</label>
				<div class="uk-form-controls">
					<select class="uk-select uk-form-small" id="grupo-entes-entes" v-model="grupoDeEntes">
						<option v-for="g in estadoGrupoDeEntes.listado" :value="g.identificador" :key="`ents-ge-${g.identificador}`">
							{{ g.descripcion }} ({{ g.identificador }})
						</option>
					</select>
				</div>
			</caption>
			<thead>
				<tr>
					<th>Identificador</th>
					<th>Correo electrónico</th>
					<th>Número telefónico</th>
					<th>Eventos</th>
				</tr>
			</thead>
			<tbody>
				<tr v-for="e in entes.estado.listado" :key="`ents-${e.identificador}`">
					<td>{{ e.identificador }}</td>
					<td>{{ e.correoElectronico }}</td>
					<td>{{ e.numeroTelefonico }}</td>
					<td>
						<strong>Total:</strong> {{ e.eventos ? e.eventos.cantidadTotal : '' }}
						<br>
						<strong>Más reciente:</strong> {{ e.eventos ? e.eventos.masReciente.fecha.toLocaleString() : '' }}
					</td>
				</tr>
			</tbody>
		</table>
		<ul class="uk-pagination uk-flex-right uk-margin-small-top uk-margin-small-bottom uk-margin-small-right" uk-margin>
			<li
				:class="{'uk-disabled': entes.estado.paginacion.indiceActual === 0 }"
			>
				<a href="#" @click="entes.listarPaginaAnterior(grupoDeEntes)"><span uk-pagination-previous></span></a>
			</li>
			<li
				v-for="n in entes.estado.paginacion.navegacion"
				:key="`pgncn-ents-${n.indice}`"
				:class="{ 'uk-active': n.activa, 'uk-disabled': !n.habilitada }"
			>
				<a href="#" @click="entes.listar(grupoDeEntes, n.indice)">{{ n.pagina }}</a>
			</li>
			<li
				:class="{'uk-disabled': entes.estado.paginacion.indiceActual === entes.estado.paginacion.indiceFinal }"
			>
				<a href="#" @click="entes.listarPaginaSiguiente(grupoDeEntes)"><span uk-pagination-next></span></a>
			</li>
		</ul>
	</div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { entes } from '@/fuentes'
import { estadoGrupoDeEntes, listarGruposDeEntesUnaVez } from '@/gruposDeEntes'

export default defineComponent({
  name: 'Entes',
  data: () => ({
		entes: entes,
		estadoGrupoDeEntes,
		grupoDeEntes: ''
	}),
	mounted() {
		listarGruposDeEntesUnaVez().then(() => {
			if (this.estadoGrupoDeEntes.listado.length === 1) {
				this.grupoDeEntes = this.estadoGrupoDeEntes.listado[0].identificador
			}
		})
	},
	watch: {
		'grupoDeEntes': function() {
			if (!this.grupoDeEntes) {
				this.entes.estado.listado = []
				return
			}

			this.entes.listar(this.grupoDeEntes, 0)
		}
	},
});
</script>
